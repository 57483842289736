<template>
    <section class="informacion-terminos-condiciones control-old overflow-auto custom-scroll">
        <div v-for="(politica,index) in politicas" :key="index" class="row mx-0 mb-4">
            <p class="col-12 f-17 f-500 mb-2">{{ politica.titulo }}</p>
            <p class="col-12 f-15 lh-18" style="white-space:pre-line;">{{ _.get(politica.texto,'texto','Sin información') }}</p>

        </div>
    </section>
</template>

<script>
import Faqs from '@/services/faqs/faqs'

export default {
    data(){
        return {
           politicas: [] 
        }
    },
    mounted(){
        this.getPoliticas()
    },
    methods: {
        async getPoliticas(){
            try {
                let tipo = 3
                const { data } = await Faqs.listar({tipo})
                this.politicas = data.data.faqs
            } catch (error) {
                this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.informacion-terminos-condiciones{
    height: 90vh;
}
</style>
